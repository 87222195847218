import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import BueroStyles from "../styles/buero.module.scss"
import SEO from "../components/seo"

const BueroPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "buero.jpg" }) {
        relativePath
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 1200, quality: 70) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Büro"
        description="Unsere Bauherren kommen mit unterschiedlichsten Projekten und mehr oder weniger ausgeprägten Vorstellungen zu uns. Wir hören ihnen intensiv zu, vertiefen uns gedanklich, fragen nach, entwickeln neue Perspektiven, präsentieren Lösungsvorschläge und kommunizieren mit ihnen eng bis zur Fertigstellung."
        image={data.file.childImageSharp.fixed.src}
      />
      <div className="container">
        <div className={BueroStyles.imgTextContainer}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            className={BueroStyles.image}
            // imgStyle={{ objectFit: "none" }}
          />
          <div className={BueroStyles.text}>
            <p>
              Unser Designbegriff ist ganzheitlicher Natur und vereint Funktion,
              sinnliche Wahrnehmung, Aufwand und Nutzen.
              <br />
              <br />
              Unsere Bauherren kommen mit unterschiedlichsten Projekten und mehr
              oder weniger ausgeprägten Vorstellungen zu uns. Wir hören ihnen
              intensiv zu, vertiefen uns gedanklich, fragen nach, entwickeln
              neue Perspektiven, präsentieren Lösungsvorschläge und
              kommunizieren mit ihnen eng bis zur Fertigstellung.
              <br />
              <br />
              Wir konzipieren Innenarchitektur, Architektur,
              Landschaftsgestaltung, Möbel, Leuchten und Abstraktes. Unser
              Bearbeitungsspektrum umfasst den gesamten Prozess von der ersten
              Entwurfsidee über die zeichnerische und modellhafte Darstellung
              bis hin zur baukonstruktiven Umsetzung. In der Schaffung von
              inhaltlichen und gestalterischen Gesamtkonzepten liegt unsere
              Stärke.
              <br />
              <br />
              Das Büro wurde 1994 von Jörg und Gabriele Schmitter gegründet und
              umfasst ein langjähriges Team aus Innenarchitekten und
              Büromitarbeitern.
              <br />
              <br />
              Innenarchitektur Schmitter ist Mitglied im Unternehmer-Netzwerk{" "}
              <a href="https://dockb-hamburg.com/" target="_blank">
                Dock B
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BueroPage
